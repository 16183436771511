var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-page-layout',{attrs:{"title":_vm.$t('hr.imprest.create_imprest'),"icon":"tw-hr-cost"},on:{"close":_vm.leavePage},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"validator"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.imprest.amount'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"decimal",rawName:"v-decimal.unsigned",modifiers:{"unsigned":true}}],attrs:{"label":_vm.$t('hr.imprest.amount'),"disabled":_vm.loading,"color":"tertiary","clearable":"","error-messages":errors},model:{value:(_vm.imprest.requested.amount),callback:function ($$v) {_vm.$set(_vm.imprest.requested, "amount", $$v)},expression:"imprest.requested.amount"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('currency.code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-picker',{attrs:{"disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.imprest.requested.code),callback:function ($$v) {_vm.$set(_vm.imprest.requested, "code", $$v)},expression:"imprest.requested.code"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.imprest.reason'),"rules":"required|max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('hr.imprest.reason'),"disabled":_vm.loading,"color":"tertiary","clearable":"","counter":512,"error-messages":errors},model:{value:(_vm.imprest.reason),callback:function ($$v) {_vm.$set(_vm.imprest, "reason", $$v)},expression:"imprest.reason"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('hr.imprest.period'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"disabled":_vm.loading,"label":_vm.$t('hr.imprest.period'),"error-messages":errors},model:{value:(_vm.imprest.period),callback:function ($$v) {_vm.$set(_vm.imprest, "period", $$v)},expression:"imprest.period"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('travel-picker',{attrs:{"disabled":_vm.loading},model:{value:(_vm.imprest.travel),callback:function ($$v) {_vm.$set(_vm.imprest, "travel", $$v)},expression:"imprest.travel"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('expense-management-picker',{attrs:{"disabled":_vm.loading,"employee":_vm.$store.state.auth.user.id},model:{value:(_vm.imprest.expenseManagement),callback:function ($$v) {_vm.$set(_vm.imprest, "expenseManagement", $$v)},expression:"imprest.expenseManagement"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"slim":"","name":_vm.$t('global.description'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('global.description'),"disabled":_vm.loading,"color":"tertiary","clearable":"","counter":512},model:{value:(_vm.imprest.description),callback:function ($$v) {_vm.$set(_vm.imprest, "description", $$v)},expression:"imprest.description"}})]}}])})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('app-page-footer',{attrs:{"create":""},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.validateAndSave}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }