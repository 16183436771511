import store from "@/store";
import CurrencyModel from "@/base/model/CurrencyModel";

export default class Imprest {
  constructor() {
    this.id = null;
    this.employee = store.state.auth.user.id;
    this.period = null;
    this.requested = new CurrencyModel();
    this.reason = null;
    this.description = null;
    this.travel = null;
    this.expenseManagement = null;
  }
}
